<template>
    <div ref="sectionEl">
        <slot></slot>
    </div>
</template>

<script setup>
import {
    onMounted, inject, ref,
} from 'vue';

const props = defineProps({
    options: {
        type: Object,
        required: true,
    },
});

const sectionEl = ref(null);

const lax = inject('lax');

onMounted(() => {
    lax.addElement(sectionEl.value, props.options);
});
</script>
