// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@splidejs/splide/css/core';
import '@splidejs/splide/css/skyblue';
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Simple & lightweight library to create smooth & beautiful animations when you scroll.
import lax from 'lax.js';

// Maps
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';
import LaxScrollAnimation from '@components/LaxScrollAnimation.vue';

// Update the CSRF field on Formie forms
import formieCsrf from './components/formie-csrf';

// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';

import {
    Splide,
    SplideTrack,
    SplideSlide,
} from '@splidejs/vue-splide';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverPanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';


createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiPopover: Popover,
        HuiPopoverButton: PopoverButton,
        HuiPopoverPanel: PopoverPanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        GoogleMapEmbed,
        LaxScrollAnimation,
        SplideCarousel: Splide,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
    },

    provide() {
        lax.init();

        lax.addDriver('scrollY', () => {
            return window.scrollY;
        });

        return {
            lax,
        };

    },

    data() {
        return {
            navActive: false,
            siteSearch: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.
        formieCsrf();
    },

}).mount('#app');
